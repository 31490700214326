`use strict`;

import React from "react";
import { GroupType } from "../../core/contact-api";

export interface Update {
    op: string;
    time: number;
}

export interface Range {
    start: number,
    end: number,
}

interface VideoProps {
    notify?: (e: any) => void;
    autoplay?: boolean;
    loop?: boolean;

    name: VideoName,
    groupType: GroupType
}

const videoEvents = [
    'abort',
    'canplay',
    'canplaythrough',
    'durationchange',
    'emptied',
    'ended',
    'error',
    'loadeddata',
    'loadedmetadata',
    'loadstart',
    'pause',
    'play',
    'playing',
    'progress',
    'ratechange',
    'seeked',
    'seeking',
    'stalled',
    'suspend',
    'timeupdate',
    'volumechange',
    'waiting',
];

export type NoVideo = ""
export type VideoName = "intro" | "pensions" | "recherche" | "carte" | "arret" | "courriers" | NoVideo

const videoSrc = (name: VideoName, groupType: GroupType): string => {
    if (name === "") return name;
    let ext = '';
    switch (groupType) {
        case GroupType.ERIT:
            ext = '-erit';
            break;
        default:
        case GroupType.INDEPENDANT:
            ext = '-sorenir';
            break;
    }
    return `/videos/${name}${ext}.mp4`
}

function VideoPlayer(props: VideoProps) {
    const [src, setSrc] = React.useState(videoSrc(props.name, props.groupType));
    React.useEffect(() => {
        setSrc(videoSrc(props.name, props.groupType));
    }, [props.name, props.groupType])
    const videoRef = React.useRef(null);
    const [videoDuration, setVideoDuration] = React.useState(0);
    const [rangeIdx, setRangeIdx] = React.useState(-1);
    const [counter, setCounter] = React.useState(0);
    const [ended, setEnded] = React.useState(false);
    const [playedRange, updateRange] = React.useReducer((state: Range[], action: Update) => {
        let nstate = [...state];
        switch (action.op) {
            case 'init':
                nstate = [];
                nstate.push({
                    start: 0,
                    end: 0,
                } as Range);
                setRangeIdx(0);
                break;

            case 'seeking':
                const seek = nstate.findIndex((r) => action.time >= r.start && action.time <= r.end);
                console.log(`seeking to range ${seek}`);
                setRangeIdx(seek);
                break;

            case 'ended':
            case 'timeupdate':
                setCounter((old: number) => old + 1);

                if ((rangeIdx < 0) || (rangeIdx >= nstate.length)) {
                    setRangeIdx(nstate.length);
                    nstate.push({
                        start: action.time,
                        end: action.time,
                    } as Range);
                    break;
                }

                let currIdx = rangeIdx;
                const cross = nstate.findIndex((r, idx) => action.time >= r.start && action.time <= r.end && currIdx != idx);
                if (cross >= 0) {
                    nstate[currIdx].end = nstate[cross].end;
                    nstate.splice(cross, 1);
                    currIdx = nstate.findIndex((r) => action.time >= r.start && action.time <= r.end);
                    setRangeIdx(currIdx);
                }
                nstate[currIdx].end = Math.max(nstate[currIdx].end, action.time);
                break;
        }
        return nstate;
    }, [] as Range[]);

    const handleVideoEvent = (evtName: string) => (evt: Event) => {
        if (evt.target) {
            const video: HTMLVideoElement = evt.target as HTMLVideoElement;
            // console.log(`video event: ${evtName} currentTime: ${video.currentTime} size: ${video.videoWidth}x${video.videoHeight}`);

            switch (evtName) {
                case 'seeking':
                    updateRange({ op: 'seeking', time: video.currentTime });
                    setCounter(0);
                    break;

                case 'ended':
                    updateRange({ op: 'ended', time: video.duration });
                    setCounter(0);
                    setEnded(true);
                    break;

                case 'durationchange':
                    setVideoDuration(video.duration);
                    break;

                case 'timeupdate':
                    updateRange({ op: 'timeupdate', time: video.currentTime });
                    break;
            }
        }
    };

    React.useEffect(() => {
        console.log(`video: ${src} ${props.autoplay && "autoplay"} ${props.loop && "loop"}`);

        setVideoDuration(0);
        updateRange({ op: 'init', time: 0 });

        const newVideoPlayerNode = document.createElement('video');
        const oldVideoPlayerNode = videoRef.current;
        newVideoPlayerNode.src = src;
        newVideoPlayerNode.controls = true;
        newVideoPlayerNode.controlsList = "nodownload";
        newVideoPlayerNode.oncontextmenu = () => { return false };
        newVideoPlayerNode.loop = props.loop === true;
        newVideoPlayerNode.autoplay = props.autoplay === true;
        videoRef.current = newVideoPlayerNode;
        const events = videoEvents.map(name => {
            const fn = handleVideoEvent(name);
            newVideoPlayerNode.addEventListener(name, fn);
            return fn;
        });
        oldVideoPlayerNode.parentNode.replaceChild(newVideoPlayerNode, oldVideoPlayerNode);

        if (props.notify) {
            props.notify({
                date: new Date(),
                src: src,
                range: playedRange,
                duration: videoDuration,
            });
        }

        return () => {
            // no leaks please
            videoEvents.forEach((name, idx) => {
                newVideoPlayerNode.removeEventListener(name, events[idx]);
            });
        };
    }, [src]);

    React.useEffect(() => {
        if (ended) {
            return;
        }
        if (counter == 0 || counter > 20) {
            if (props.notify) {
                props.notify({
                    date: new Date(),
                    src: src,
                    range: playedRange,
                    duration: videoDuration,
                });
            }
        }
    }, [ended, counter, videoDuration])

    return (
        <>
            <div className="player">
                <video controlsList="nodownload" ref={videoRef} />
            </div>

            {/* <div>
                {playedRange.map((r: Range) => <div>{r.start} - {r.end}</div>)}
            </div>
            <div style={{ width: "700px", backgroundColor: "#000", height: "15px", display: "inline-block", position: "relative" }}>
                {playedRange.map((r: Range) => <div style={{
                    left: ((r.start / videoDuration) * 700) + "px",
                    width: (((r.end - r.start) / videoDuration) * 700) + "px",
                    backgroundColor: "#444", height: "15px", display: "inline-block", position: "absolute"
                }}>
                </div>)}
            </div> */}

        </>
    );
}

export default VideoPlayer;
