import React from "react";
import ReactDOM from "react-dom/client";

import Connect from "../../components/connect/Connect";
import Training from "../../components/training/Training";
import { createContactInternalAPI } from "../../core/contact-internal-api";

ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
        <App></App>
    </React.StrictMode>
);

const genUuid = () => {
    return ("" + 1e7 + -1e3 + -1e3 + -8e3 + -1e11).replace(/[018]/g, c => {
        const r = crypto.getRandomValues(new Uint8Array(1))[0] & 0xf;
        return r.toString(16);
    });
}

const uuid = genUuid();
const contactApi = createContactInternalAPI()

function App() {
    const [groupType, setGroupType] = React.useState(null);

    const next = (groupType: string) => {
        setGroupType(groupType)
    }

    return <div className="page-single">
        {!groupType && <Connect uuid={uuid} onNext={next} connect={contactApi.connectByEmail} />}
        {groupType && <Training uuid={uuid} groupType={groupType}/>}
    </div>;
}
