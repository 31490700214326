`use strict`;

import React from "react";
import { ConnectByEmailResponse } from "../../core/contact-api";
import { isEmailValid } from "../../core/validation";

interface ConnectProps {
    uuid: string;
    onNext?: (groupType: string) => void;
    connect(uuid: string, email: string): Promise<ConnectByEmailResponse>
}

function Connect(props: ConnectProps) {
    const [error, setError] = React.useState("");
    const [code, setCode] = React.useState("");

    const onConnect = (evt: React.ClickEvent<HTMLButtonElement>) => {
        evt.preventDefault();
        props.connect(props.uuid, code)
            .then((_data: ConnectByEmailResponse) => {
                if (!props.onNext) {
                    window.location.href = "/training"
                    return;
                }
                props.onNext(_data.groupType);
                return;
            })
            .catch((_data) => {
                setError(`Votre mail « ${code} » est inconnu, veuillez appeler notre service au `);
            })
        ;
    }

    React.useEffect(() => {
        document.body.className = "connect";
    }, []);

    const onChangeCode = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const target = evt.currentTarget;
        const value = target?.value;
        setError(null)
        if (!isEmailValid(value)) {
            setCode("");
            return;
        }
        setCode(value);
    }
    return <>
        <div id="arc">
            <img src="/img/arc-bleu.png" alt="" />
        </div>
        {error && (
            <div id="alert">
                <div>{error}</div>
                <button onClick={() => setError("")}>X</button>
            </div>
        )}
        <main>
            <div id="logo">
                
            </div>
            <div id="title">
                <h1>LES SERVICES D'ACCOMPAGNEMENTS ADMINISTRATIFS APRÈS DÉCÈS</h1>
            </div>
            <div id="leftbar">
                <div className="bar"></div>
            </div>
            <form onSubmit={onConnect} id="login">
                <h2>CONNECTEZ-VOUS AVEC VOTRE EMAIL</h2>
                <div id="line"><input onChange={onChangeCode} type="text"  /></div>
                <div id="submit"><button disabled={code === ""} type="submit">SE CONNECTER</button></div>
            </form>
        </main>
    </>
}

export default Connect;
