import React from 'react'
import { GroupType } from '../../core/contact-api'
type Props = {
    groupType: GroupType
}
export const Arc = (props: Props)=>{
    let ext = '';
    switch (props.groupType) {
        case GroupType.ERIT:
            ext = '-erit';
            break;
        default:
        case GroupType.INDEPENDANT:
            ext = '-sorenir';
            break;
    }
    return <img src={`/img/arc${ext}.png`} alt="" />
}