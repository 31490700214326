import React from 'react'
import { GroupType } from '../../core/contact-api'

type IconProps = {
    groupType: GroupType
}
export const IconPension = (props: IconProps)=>{
    if (props.groupType === GroupType.ERIT) 
        return <img src="/img/icons/logo-pension-erit.png" alt="" /> 
    
    return <img src="/img/icons/logo-pension-sorenir.png" alt="" />
}

export const IconFunds = (props: IconProps)=>{
    if (props.groupType === GroupType.ERIT) 
        return <img src="/img/icons/logo-recherche-erit.png" alt="" /> 
    return <img src="/img/icons/logo-recherche-sorenir.png" alt="" />
}

export const IconCarteGrise = (props: IconProps)=>{
    if (props.groupType === GroupType.ERIT) 
        return <img src="/img/icons/logo-carte-grise-erit.png" alt="" /> 

    return <img src="/img/icons/logo-carte-grise-sorenir.png" alt="" />
}


export const IconResiliationNumerique = (props: IconProps)=>{
    if (props.groupType === GroupType.ERIT) 
        return <img src="/img/icons/logo-resiliation-numerique-erit.png" alt="" /> 
    return <img src="/img/icons/logo-resiliation-numerique-sorenir.png" alt="" />
}


export const IconFormalites = (props: IconProps)=>{
    if (props.groupType === GroupType.ERIT) 
        return <img src="/img/icons/logo-resiliation-abonnements-erit.png" alt="" /> 
    return <img src="/img/icons/logo-resiliation-abonnements-sorenir.png" alt="" />
}