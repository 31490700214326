import { GroupType } from "../core/contact-api";

export const themes = {
    colors: {
        [GroupType.INDEPENDANT]:  {
            red : "#D24746",
            gold: '#C09D38'
        },
        [GroupType.ERIT] :{
            red : "#c8b49b",
            gold: '#A48257'           
        }
    }
}