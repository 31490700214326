import React from 'react'
import { GroupType } from "../../core/contact-api"

type Props = {
    groupType: GroupType
}

export const Logo = (props: Props) => {
    let ext = '';
    switch (props.groupType) {
        case GroupType.ERIT:
            ext = '-erit';
            break;
        default:
        case GroupType.INDEPENDANT:
            ext = '-sorenir';
            break;
    }
    const logoPath = `/img/logo${ext}.png`;

    return <img src={logoPath} alt="" />
}
